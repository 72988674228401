import React, { useState } from "react";
import * as style from "./map.module.css";
import Toggle from "../toggle/Toggle";
import Centre from "../toggle/Centre";
import { graphql, useStaticQuery } from "gatsby";
import GMap from "./GMap";

const Map = ({ width, height }) => {
    const { sanityMap } = useStaticQuery(getData);
    const [map, setMap] = useState(null);
    const [centre, setCentre] = useState(sanityMap.centre);

    return (
        <section
            className={style.map}
            style={{
                width: width,
                height: height,
            }}
            role="button"
            tabIndex="0"
        >
            <div className={style.toggleWrapper}>
                <Toggle to={"map"} colour={"#000000"} />
            </div>
            <div className={style.centreWrapper}>
                <Centre centre={sanityMap.centre} setCentre={setCentre} />
            </div>
            <GMap map={map} setMap={setMap} centre={centre} setCentre={setCentre} />
        </section>
    );
};

export default Map;

const getData = graphql`
    {
        sanityMap {
            _rawLocations(resolveReferences: { maxDepth: 5 })
            _rawOffice
            centre {
                lat
                lng
            }
        }
    }
`;
