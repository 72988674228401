import React from "react";
import * as style from "./centre.module.css";
import { motion } from "framer-motion";

const Centre = ({ centre, setCentre }) => {
    return (
        <motion.button
            whileHover={{
                scale: 1.1,
            }}
            whileTap={{
                scale: 0.9,
            }}
            onClick={() => setCentre(centre)}
            className={style.centre}
        >
            <svg width="50" height="50" viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="23" fill="none" stroke="#000" strokeWidth="3" vectorEffect="non-scaling-stroke" />
                <circle cx="25" cy="25" r="7" fill="#000000" stroke="#000" strokeWidth="3" vectorEffect="non-scaling-stroke" />
            </svg>
        </motion.button>
    );
};

export default Centre;
