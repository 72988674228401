import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import mapStyle from "./mapStyle.json";
import MapMarker from "./MapMarker";
import { graphql, useStaticQuery } from "gatsby";
import { useActiveContext, useActiveUpdateContext } from "../../state/GlobalState";

const mapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: mapStyle,
};

const GMap = ({map, setMap, centre, setCentre}) => {
    const {
        sanityMap,
        allSanityLocations: { locations },
    } = useStaticQuery(getData);

    const onLoad = useCallback(function callback(map) {
        setMap(map);
    }, []);

    const { isLoaded } = useJsApiLoader({
        id: "68ca468fa5a35537",
        googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    });

    const changeCentre = () => {
        if (map) {
            const newCentre = map.center;
            const lat = newCentre.lat().toString();
            const lng = newCentre.lng().toString();
            setCentre({ lat: lat, lng: lng });
        }
    };

    const ActiveContext = useActiveContext();
    const UpdateActiveContext = useActiveUpdateContext();

    return isLoaded ? (
        <GoogleMap
            onLoad={onLoad}
            options={mapOptions}
            mapContainerStyle={{
                width: "100%",
                height: "100%",
                fontSize: "var(--font-size-small)",
                color: "#000000",
            }}
            center={centre}
            defaultCenter={sanityMap.centre}
            zoom={14}
            onCenterChanged={changeCentre}
        >
            {locations.map((location) => {
                return (
                    <MapMarker
                        key={location._id}
                        label={location.name}
                        lat={location.location.lat}
                        lng={location.location.lng}
                        active={ActiveContext}
                        setActive={UpdateActiveContext}
                    />
                );
            })}
        </GoogleMap>
    ) : (
        <></>
    );
};

export default GMap;

const getData = graphql`
    {
        sanityMap {
            centre {
                lat
                lng
            }
        }
        allSanityLocations {
            locations: nodes {
                _id
                name
                location {
                    lat
                    lng
                }
            }
        }
    }
`;
