import React from "react";
import { Marker } from "@react-google-maps/api";

const MapMarker = ({ lat, lng, label, active, setActive }) => {
    const hasWindow = typeof window !== undefined;
    return (
        <Marker
            position={{ lat: lat, lng: lng }}
            title={label}
            icon={{
                path: hasWindow ? window.google.maps.SymbolPath.CIRCLE : "",
                scale: 8,
                fillOpacity: 1,
            }}
            animation={active === label ? hasWindow ? window.google.maps.Animation.BOUNCE : "" : ""}
            onMouseOver={() => setActive(label)}
            onMouseOut={() => setActive("")}
        />
    );
};

export default MapMarker;
