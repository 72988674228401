import React, { useState, useMemo, useEffect } from "react";

import * as style from "../styles/index.module.css";
import Title from "../components/title/Title";
import Contact from "../components/contact/Contact";
import About from "../components/about/About";
import Map from "../components/map/Map";
import { useMousePosition } from "../hooks/useMousePosition";
import { useWindowSize } from "../hooks/useWindowSize";

const Index = () => {
    const { mouseX, mouseY } = useMousePosition();
    const { windowWidth, windowHeight } = useWindowSize();
    const hasWindow = typeof window !== "undefined";
    const delta = 20;
    const [mobile, setMobile] = useState(false);

    const mql = useMemo(() => {
        if (hasWindow) return window.matchMedia("(max-width: 750px)");
    }, [hasWindow]);

    useEffect(() => (mql.matches ? setMobile(true) : setMobile(false)), [mql?.matches]);

    const widthPos = Math.max(0, windowWidth / 2 + Math.sqrt(mouseX * mouseX) / delta);
    const heightPos = Math.max(0, windowHeight / 2 + Math.sqrt(mouseY * mouseY) / delta);
    const widthNeg = Math.max(0, windowWidth / 2 - Math.sqrt(mouseX * mouseX) / delta);
    const heightNeg = Math.max(0, windowHeight / 2 - Math.sqrt(mouseY * mouseY) / delta);

    return (
        <div className={style.container}>
            <div className={style.grid}>
                <Title width={mobile ? "100vw" : widthNeg} height={mobile ? "50vh" : heightNeg} />
                <Contact width={mobile ? "100vw" : widthPos} height={mobile ? "50vh" : heightNeg} />
                <About width={mobile ? "100vw" : widthNeg} height={mobile ? "50vh" : heightPos} />
                <Map width={mobile ? "100vw" : widthPos} height={mobile ? "50vh" : heightPos} />
            </div>
        </div>
    );
};

export default Index;
