import { useEffect, useState } from "react";

export const useMousePosition = () => {
    const [position, setPosition] = useState({ mouseX: 0, mouseY: 0 });

    useEffect(() => {
        const handleCursor = (e) => setPosition({ mouseX: e.clientX, mouseY: e.clientY });
        window.addEventListener("mousemove", handleCursor);
        return () => window.removeEventListener("mousemove", handleCursor);
    }, []);

    return position;
};
