import React from "react";
import * as style from "./contact.module.css";
import Toggle from "../toggle/Toggle";
import { graphql, useStaticQuery } from "gatsby";
import { PortableText } from "@portabletext/react";

const components = {
    marks: {
        link: ({ value, children }) => {
            const type = () => {
                switch (value.type) {
                    case "email":
                        return (
                            <a
                                className={style.link}
                                href={`mailto:${value.link}?subject=${value.subject}`}
                            >
                                {children}
                            </a>
                        );
                    case "phone":
                        return (
                            <a className={style.link} href={`tel:${value.link}`}>
                                {children}
                            </a>
                        );
                    default:
                        return (
                            <a
                                className={style.link}
                                href={`${value.link}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {children}
                            </a>
                        );
                }
            };
            return type();
        },
    },
};

const Contact = ({ width, height }) => {
    const { contact } = useStaticQuery(getData);
    return (
        <section
            className={style.contact}
            style={{
                width: width,
                height: height,
            }}
            role="button"
            tabIndex="0"
        >
            <div className={style.toggleWrapper}>
                <Toggle to={"contact"} colour={"#000000"} />
            </div>
            <div className={style.text}>
                <PortableText value={contact._rawContact} components={components} />
                <div className={style.socials}>
                    <PortableText value={contact._rawSocials} components={components} />
                </div>
            </div>
        </section>
    );
};

export default Contact;

const getData = graphql`
    {
        contact: sanityContact {
            _rawContact(resolveReferences: { maxDepth: 1 })
            _rawSocials(resolveReferences: { maxDepth: 1 })
        }
    }
`;
