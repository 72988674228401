import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as style from "./about.module.css";
import Toggle from "../toggle/Toggle";
import { PortableText } from "@portabletext/react";

const About = ({ width, height}) => {
    const { about } = useStaticQuery(getData);

    return (
        <section
            className={style.about}
            style={{
                width: width,
                height: height,
            }}
            role="button"
            tabIndex="0"
        >
            <div className={style.toggleWrapper}>
                <Toggle to={"about"} colour={"#000000"} />
            </div>
            <div className={style.text}>
                <PortableText value={about._rawHeadline} />
            </div>
        </section>
    );
};

export default About;

const getData = graphql`
    {
        about: sanityAbout {
            _rawHeadline
        }
    }
`;
