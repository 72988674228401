import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as style from "./title.module.css";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import Image from "gatsby-plugin-sanity-image";

const variants = {
    up: { y: "18vh" },
    center: { y: "0" },
    down: { y: "-18vh" },
};

const variantsAlt = {
    up: { y: "-18vh" },
    center: { y: "0" },
    down: { y: "18vh" },
};

const variantsImage = {
    up: { scale: 1 },
    center: { scale: 0 },
    down: { scale: 1 },
};

const Title = ({ width, height }) => {
    const [status, setStatus] = useState("center");
    const [last, setLast] = useState("down");
    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const { title } = useStaticQuery(getData);

    const handleHover = () => {
        if (status === "up" || status === "down") {
            setLast(status);
            setStatus("center");
            return;
        }
        if (last === "up") {
            setStatus("down");
            return;
        }
        if (last === "down") {
            setStatus("up");
            return;
        }
    };

    return (
        <button
            className={style.title}
            style={{ width: width, height: height }}
            onMouseEnter={() => {
                if (!isMobile) {
                    setIndex((prevState) => (prevState + 1) % title.images.length);
                    handleHover();
                }
            }}
            onMouseLeave={() => {
                if (!isMobile) handleHover();
            }}
            onClick={() => {
                if (isMobile) {
                    if (!open) setIndex((prevState) => (prevState + 1) % title.images.length);
                    setOpen((prevState) => !prevState);
                    handleHover();
                }
            }}
            tabIndex="0"
        >
            <motion.h1 animate={status} variants={variants} initial={"center"}>
                Cameo
            </motion.h1>
            <h1>&nbsp;</h1>
            <motion.h1 animate={status} variants={variantsAlt} initial={"center"}>
                Studios
            </motion.h1>
            <div className={style.thumbnail}>
                {title.images.map((image, i) => {
                    return (
                        <motion.div
                            key={image._key}
                            className={style.image}
                            animate={status}
                            variants={variantsImage}
                            initial={"center"}
                           style={{ display: i === index ? "block" : "none" }}
                        >
                            <Image {...image} alt="" width={200} />
                        </motion.div>
                    );
                })}
            </div>
        </button>
    );
};

export default Title;

const getData = graphql`
    {
        title: sanityTitle {
            text
            images {
                _key
                ...Image
            }
        }
    }
`;
